import React from 'react'

import { Badge, Col, Row, Table } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faEdit, faLevelUpAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFacilityKinds } from './facility_kinds.graphql'

import { DD_MM_YYYY } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import FormCreate from './create_form'
import FormDelete from './delete_form'
import FormUpdate from './form_update'

const FacilityKinds = ({ locale, history, client, match, data: { loading, error, facility_kinds } }) => {
  if (loading)
    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="8">
            <h1>{locale.facility_kind.plural}</h1>
          </Col>
          <Col sm="4" className="text-right">
            <Modal
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              children={<FormCreate locale={locale} client={client} history={history} />}
              modal_size={'lg'}
              toggleModalText={locale.facility_kind.add}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Loading />
      </div>
    )
  if (error) return <p>Error</p>
  return (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col sm="8">
          <h1>{locale.facility_kind.plural}</h1>
        </Col>
        <Col sm="4" className="text-right">
          <Modal
            button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
            children={<FormCreate locale={locale} client={client} history={history} />}
            modal_size={'lg'}
            toggleModalText={locale.facility_kind.add}
          />
        </Col>
      </Row>
      <hr className="seperator" />
      <FacilityKindsTable locale={locale} history={history} facility_kinds={facility_kinds} />
    </div>
  )
}

class FacilityKindsTable extends React.Component {
  render = () => (
    <React.Fragment>
      <Table striped responsive bordered>
        <thead className="thead-light">
          <tr>
            <th>{this.props.locale.facility_kind.label}</th>
            <th>{this.props.locale.facility_kind.volumeUnitCalculationStrategy}</th>
            <th>{this.props.locale.facility_kind.estimatedFacilitySize}</th>
            <th>{this.props.locale.facility_kind.sections_count}</th>
            <th>{this.props.locale.facility_kind.created_at}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.facility_kinds.map(facility_kind => (
            <FacilityKind
              facility_kind={facility_kind}
              history={this.props.history}
              key={facility_kind.id}
              locale={this.props.locale}
            />
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

const getTranslatedFacilityStrategy = (facility_kind, locale) => {
  const facilityStrategyKey = `${facility_kind.volumeUnitCalculationStrategy}`.toLowerCase()
  return locale.facility_kind.strategies[facilityStrategyKey]
}

const estimatedFacilitySizeInformation = facilitySize => {
  if (facilitySize === null || facilitySize === undefined) {
    return '-'
  }

  return (
    <Badge pill color="info">
      {facilitySize}
    </Badge>
  )
}

class FacilityKind extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { facility_kind, locale, history, is_subkind, client } = this.props
    return (
      <React.Fragment>
        <tr>
          <td>
            {is_subkind ? (
              <React.Fragment>
                <FontAwesomeIcon className="icon-prepend" icon={faLevelUpAlt} fixedWidth rotation={90} />
                {facility_kind.name_de}
              </React.Fragment>
            ) : (
              <strong>{facility_kind.name_de}</strong>
            )}
          </td>
          <td>{getTranslatedFacilityStrategy(facility_kind, locale)}</td>
          <td>{estimatedFacilitySizeInformation(facility_kind.estimatedFacilitySize)}</td>
          <td>{facility_kind.sections_count}</td>
          <td>{DD_MM_YYYY(facility_kind.created_at)}</td>
          <td className="text-center">
            <Modal
              toggleModalText={`Angebotstyp ${locale.edit}`}
              children={<FormUpdate locale={locale} client={client} history={history} facility_kind={facility_kind} />}
              button_type={{
                icon_only: true,
                icon_type: faEdit,
                color: 'primary-light',
                classes: 'btn-transparent btn-icon-only'
              }}
              modal_size={'lg'}
            />

            {facility_kind.deletable && (
              <Modal
                toggleModalText={`Angebotstyp ${locale.delete}`}
                children={
                  <FormDelete locale={locale} client={client} history={history} facility_kind={facility_kind} />
                }
                button_type={{
                  icon_only: true,
                  icon_type: faTrashAlt,
                  color: 'primary-light',
                  classes: 'btn-transparent btn-icon-only text-danger'
                }}
                modal_size={'lg'}
              />
            )}
          </td>
        </tr>
        {facility_kind?.sub_kinds &&
          facility_kind.sub_kinds.map(sub_kind => (
            <FacilityKind
              facility_kind={sub_kind}
              history={history}
              is_subkind={true}
              key={sub_kind.id}
              locale={locale}
            />
          ))}
      </React.Fragment>
    )
  }
}

export default withApollo(
  graphql(getFacilityKinds, {
    options: ({
      match: {
        params: { id }
      }
    }) => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id
      }
    })
  })(FacilityKinds)
)
