import { Alert } from 'reactstrap'

import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'

import { getStates } from './select-queries.graphql'

const StatesSelect = ({ federalState, setFederalState, invalid, locale }) => {
  const { loading, error, data } = useQuery(getStates, { variables: { country_code: 'DE' } })

  if (error) {
    console.error('Error fetching possible States', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching possible states
      </Alert>
    )
  }

  const options =
    data?.possible_states?.map(possibleFederalState => ({
      value: possibleFederalState.key,
      label: possibleFederalState.label
    })) || []

  options.unshift({
    value: 'other',
    label: 'Sonstige'
  })

  const value = options.find(option => option.value === federalState)

  const onChange = selectedOption => {
    setFederalState(selectedOption.value)
  }

  return (
    <>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        invalid={invalid}
        placeholder={'auswählen'}
      />
    </>
  )
}

export default withLocale(StatesSelect, { key: 'statesSelect' })
