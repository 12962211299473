import { Input, Table } from 'reactstrap'

import { withLocale } from 'locale'

const LicenseOwnerPricesTable = ({ locale, generalLocale, prices, facilitySizes, onChange }) => {
  return (
    <>
      <p>{locale.pricesTable}</p>
      <Table bordered>
        <thead className="thead-light">
          <tr>
            <th></th>
            <th>{locale.collection}</th>
            <th>{locale.certification}</th>
            <th>{locale.organisation}</th>
            <th>{locale.other}</th>
            <th>{locale.discountAndFunding}</th>
          </tr>
        </thead>
        <tbody>
          {facilitySizes.possibleFacilitySizes.map((facilitySize, index) => (
            <PricesTableColumn
              onChange={onChange}
              facilitySize={facilitySize}
              prices={prices}
              index={index}
              key={facilitySize}
            ></PricesTableColumn>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const PricesTableColumn = ({ facilitySize, prices, onChange, index }) => {
  return (
    <tr>
      <th>{facilitySize}</th>
      <td>
        <EditPriceInput
          currentPrice={prices}
          type="collection"
          facilitySize={facilitySize}
          onChange={onChange}
          index={index}
        ></EditPriceInput>
      </td>
      <td>
        <EditPriceInput
          currentPrice={prices}
          type="certification"
          facilitySize={facilitySize}
          onChange={onChange}
          index={index}
        ></EditPriceInput>
      </td>
      <td>
        <EditPriceInput
          currentPrice={prices}
          type="organisation"
          facilitySize={facilitySize}
          onChange={onChange}
          index={index}
        ></EditPriceInput>
      </td>
      <td>
        <EditPriceInput
          currentPrice={prices}
          type="other"
          facilitySize={facilitySize}
          onChange={onChange}
          index={index}
        ></EditPriceInput>
      </td>
      <td>
        <EditPriceInput
          currentPrice={prices}
          type="discountAndFunding"
          facilitySize={facilitySize}
          onChange={onChange}
          index={index}
        ></EditPriceInput>
      </td>
    </tr>
  )
}

const EditPriceInput = ({ currentPrice, type, facilitySize, onChange, index }) => {
  return (
    <Input
      value={currentPrice[index][type]}
      type="number"
      step="0.01"
      onChange={event => onChange(event.target.value, type, facilitySize)}
    />
  )
}

export default withLocale(withLocale(LicenseOwnerPricesTable, { key: 'licenseOwners.automatedTendersInformation' }), {
  attributeName: 'generalLocale'
})
