import RichTextEditor from 'react-rte'

import { withLocale } from 'locale'

const AdditionalInfoOnPricesInput = ({ locale, generalLocale, priceInfoText, onChange }) => (
  <>
    <p>{locale.priceInfoTextInfo}</p>
    <RichTextEditor value={priceInfoText} onChange={onChange} />
  </>
)

export default withLocale(
  withLocale(AdditionalInfoOnPricesInput, { key: 'licenseOwners.automatedTendersInformation' }),
  {
    attributeName: 'generalLocale'
  }
)
