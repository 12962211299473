import gql from 'graphql-tag'

export const regionsQuery = gql`
  query regions_query {
    regions {
      name_de
      id
      stateName
    }
  }
`
