import { useState } from 'react'

import RichTextEditor from 'react-rte'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useMutation, useQuery } from '@apollo/client'

import Loading from 'components/helper/loading/loading'
import { ProcessingButton } from 'components/modules/buttons/processing-button'
import ValidatedInput from 'components/modules/inputs/validated-input'
import { getLicenseOwners } from 'components/views/admin/license-owners/license-owners.graphql'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import { withLocale } from 'locale'

import { getFacilitySizes, updateAutomatedTenderInformation } from './automatedTendersCell.graphql'

import AdditionalInfoOnPricesInput from './additionalInfoOnPricesInput'
import LicenseOwnerPricesTable from './licenseOwnerPricesTable'

const stripEmptyHtml = value => ['<p><br></p>', '\n'].reduce((val, substr) => val?.replace(substr, ''), value || '')

const AutomatedTendersCell = ({ licenseOwner, locale, generalLocale, tooltipUuid }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const { loading, error, data } = useQuery(getFacilitySizes, {
    variables: {},
    notifyOnNetworkStatusChange: true
  })

  const mapData = originalData => {
    return originalData.map(element => {
      const { __typename, ...mappedElement } = element
      return mappedElement
    })
  }

  const [priceInfoText, setPriceInfoText] = useState(
    RichTextEditor.createValueFromString(licenseOwner.priceInfoText || '', 'html')
  )
  const [prices, setPrices] = useState(mapData(licenseOwner.licenseOwnerPrices))
  const [allowAutomatedTenderGeneration, setAllowAutomatTenderGeneration] = useState(
    licenseOwner.allowsAutomatedTenderCreation
  )

  const setPriceSpecialLogic = (currentPrice, type, facilitySize) => {
    let newPrices = mapData(prices)
    const priceIndex = newPrices.findIndex(element => {
      return element.facilitySize == facilitySize
    })
    newPrices[priceIndex][type] = parseFloat(currentPrice)
    setPrices(newPrices)
  }

  const [
    updateAutomatedTenderInformationMutation,
    { loading: loadingOfMutation, error: errorOfMutation, data: dataOfMutation }
  ] = useMutation(updateAutomatedTenderInformation, {
    variables: {
      licenseOwnerId: licenseOwner.id,
      prices: prices,
      allowsAutomatedTenderCreation: allowAutomatedTenderGeneration,
      priceInfoText: stripEmptyHtml(priceInfoText.toString('html'))
    },
    refetchQueries: [{ query: getLicenseOwners }]
  })

  if (error || errorOfMutation) return `Errors! ${error}`

  return (
    <>
      <Button size={`sm`} className="link-btn" color={'link'} onClick={toggle} id={`tooltip-modal_${tooltipUuid}`}>
        <p>{locale.editOfferText}</p>
      </Button>
      <Modal centered isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>Preise Bearbeiten für: {licenseOwner.name}</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ValidatedInput
                id={'allowsAutomatedTenderGeneration'}
                type={'checkbox'}
                variables={deepNestInObjectsWithKeys(
                  licenseOwner.allowsAutomatedTenderCreation,
                  'allowsAutomatedTenderGeneration'
                )}
                onChange={event => {
                  setAllowAutomatTenderGeneration(event.target.checked)
                }}
                locale={locale}
                customLabel={locale.allowSelfSignUp}
              />
              <hr className="seperator border-gray" />
              <LicenseOwnerPricesTable
                licenseOwner={licenseOwner}
                prices={prices}
                facilitySizes={data}
                onChange={setPriceSpecialLogic}
              />
              <AdditionalInfoOnPricesInput priceInfoText={priceInfoText} onChange={setPriceInfoText} />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            {generalLocale.close}
          </Button>
          <ProcessingButton
            onClick={updateAutomatedTenderInformationMutation}
            label={generalLocale.save}
            processing={loadingOfMutation}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withLocale(withLocale(AutomatedTendersCell, { key: 'licenseOwners.automatedTendersInformation' }), {
  attributeName: 'generalLocale'
})
